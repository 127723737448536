<template>
  <Container class="flex justify-center gap-8 mb-12">
    <div>
      <button :disabled="!(page > 1)"
              class="flex items-center justify-center w-16 h-16 text-gray-200 duration-100 border border-gray-200 rounded-full hover:text-white hover:border-white disabled:cursor-not-allowed disabled:text-gray-300 disabled:border-gray-300"
              @click="handlePrevPage"
      >
        <span class="sr-only">Previous page</span>
        <IconChevronDown class="w-6 h-6 rotate-90" />
      </button>
    </div>
    <div>
      <button :disabled="!(totalItems > page * 9)"
              class="flex items-center justify-center w-16 h-16 text-gray-200 duration-100 border border-gray-200 rounded-full hover:text-white hover:border-white disabled:cursor-not-allowed disabled:text-gray-300 disabled:border-gray-300"
              @click="handleNextPage"
      >
        <span class="sr-only">Next page</span>
        <IconChevronDown class="w-6 h-6 -rotate-90" />
      </button>
    </div>
  </Container>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

type BlogPagination = {
  totalItems: number
  queryParam: string
}

const props = defineProps<BlogPagination>()

const route = useRoute()
const router = useRouter()

const page = ref(route.query[props.queryParam] ? Number(route.query[props.queryParam]) : 1)

// watch route.query.page and set it to page.value
watch(
  () => route.query[props.queryParam],
  () => {
    page.value = route.query[props.queryParam] ? Number(route.query[props.queryParam]) : 1
  }
)

function handleNextPage() {
  router.push({
    query: {
      ...route.query,
      [props.queryParam]: page.value + 1
    }
  })
}

function handlePrevPage() {
  router.push({
    query: {
      ...route.query,
      [props.queryParam]: page.value - 1
    }
  })
}
</script>
